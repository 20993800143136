import React from 'react'

import {
  GenericEditableTable,
  CommonGenericEditableTableRowOptions,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import {
  onboardingEmployeesEmailColumn,
  onboardingEmployeesFullNameColumn,
  onboardingEmployeesRoleColumn,
  onboardingEmployeesSeniorityColumn,
  onboardingEmployeesTeamColumn,
} from '@src/constants/columns/importEmployeesV2'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<RevolutersInterface>> => ({
  cells: [
    {
      ...onboardingEmployeesEmailColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingEmployeesFullNameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingEmployeesTeamColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingEmployeesRoleColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingEmployeesSeniorityColumn(options.onChange),
      width: 150,
    },
  ],
})

export const ImportEmployeesImport = () => {
  return (
    <GenericEditableTable
      apiEndpoint={API.EMPLOYEES}
      tableName={TableNames.ImportEmployeesV2}
      row={row}
      entity="employee"
      variant="existingEntities"
      tableActions={props => (
        <MoreBar>
          <MoreBar.Action
            use={InternalLink}
            to={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT}
            useIcon="Upload"
          >
            Import employees
          </MoreBar.Action>

          <BulkEditExistingEntitiesAction
            buttonIcon="Suitcase"
            field="team"
            selector={selectorKeys.team}
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
          />
          <BulkEditExistingEntitiesAction
            buttonIcon="RepairTool"
            field="specialisation"
            selector={selectorKeys.specialisations}
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            label="role"
          />
          <BulkEditExistingEntitiesAction
            buttonIcon="ArrowRightLeft"
            field="seniority"
            selector={selectorKeys.seniority}
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
          />
        </MoreBar>
      )}
    />
  )
}

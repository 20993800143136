import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { importEmployeesConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { ImportEmployeesIntro } from './ImportEmployeesIntro'
import { ImportEmployeesImport } from './ImportEmployeesImport'
import { ImportEmployeesFlow } from './ImportEmployeesFlow'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: ImportEmployeesIntro,
  },
  {
    title: 'Import',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: ImportEmployeesImport,
  },
]

export const ImportEmployees = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.ANY}>
        <ImportEmployeesFlow />
      </Route>
      <Route>
        <OnboardingChecklistContent config={importEmployeesConfig} routes={routes} />
      </Route>
    </Switch>
  )
}

import React, { useState } from 'react'

import { documentsBulkRequestFormRequests } from '@src/api/documents'
import Form from '@src/features/Form/Form'
import { PageBody } from '@src/components/Page/PageBody'
import { AttachedTemplateInterface } from '@src/interfaces/documentsTemplates'
import { SelectTemplatesSidebar } from '../components/SelectTemplatesSidebar'
import { CommonTabProps } from '../../common'
import { BasicsTabContent } from './BasicsTabContent'

export const Basics = (props: CommonTabProps) => {
  const [activeSidebar, setActiveSidebar] = useState<'template'>()
  const [attachedTemplates, setAttachedTemplates] = useState<AttachedTemplateInterface[]>(
    [],
  )

  return (
    <>
      <PageBody>
        <Form
          useEntityIdFromData
          disableLocalStorageCaching
          api={documentsBulkRequestFormRequests}
        >
          <BasicsTabContent
            {...props}
            openTemplatesSidebar={() => setActiveSidebar('template')}
            attachedTemplates={attachedTemplates}
            initAttachedTemplates={templates => setAttachedTemplates(templates)}
            deleteAttachedTemplate={templateId =>
              setAttachedTemplates(attachedTemplates.filter(t => t.id !== templateId))
            }
          />
        </Form>
      </PageBody>
      {activeSidebar === 'template' && (
        <SelectTemplatesSidebar
          isOpen={activeSidebar === 'template'}
          onClose={() => setActiveSidebar(undefined)}
          attachedTemplates={attachedTemplates}
          onAddTemplate={newTemplate => {
            setAttachedTemplates([...attachedTemplates, newTemplate])
          }}
        />
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Flex,
  Text,
  Icon,
  Token,
  useTooltip,
  Box,
  Tooltip,
  TextSkeleton,
  useStatusPopup,
  Popup,
  Header,
  StatusPopup,
  Footnote,
  Button,
  MoreBar,
  IconName,
} from '@revolut/ui-kit'
import { get } from 'lodash'

import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ColumnInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import EditableCell from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { selectorKeys } from '@src/constants/api'
import {
  bulkDeleteImportSessionRows,
  bulkEditImportSessionRows,
  createImportFromEntities,
  getImportSessionBulkItems,
} from '@src/api/bulkDataImport'
import { IdAndName } from '@src/interfaces'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { TableActionsProps } from './GenericEditableTable'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

export type ImportInputOnChange = (
  rowId: number,
  value:
    | string
    | number
    | null
    | undefined
    | {
        id?: string | number | undefined
        name?: string | null | undefined
      },
  field: string,
) => void

export type ImportSelectInputColumn<T> = (
  onChange: ImportInputOnChange,
) => ColumnInterface<ImportInterface<T>>

type ImportSelectInputProps<T> = {
  onChange: ImportInputOnChange
  selector: selectorKeys
  field: string
  fieldName?: string
  data: ImportInterface<T>
  useNameField?: boolean
}

export const RadioSelectInputCell = <T,>({
  data,
  onChange,
  selector,
  field,
  fieldName,
  useNameField,
}: ImportSelectInputProps<T>) => {
  return (
    <CellWithError data={data} field={field}>
      <RadioSelectInput
        onChange={option =>
          onChange(data.id, useNameField ? option?.name : option, field)
        }
        selector={selector}
        useQuery
        useQueryOptions={{
          cacheTime: Infinity,
          staleTime: Infinity,
        }}
        renderInput={(open, setOpen, ref) => (
          <Flex
            onClick={() => setOpen(!open)}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            use="button"
            type="button"
            color="inherit"
            ref={ref}
          >
            <Text textOverflow="ellipsis" overflow="hidden">
              {get(data.data, `${field}.name`) ||
                get(data.data, field) ||
                `Select ${fieldName || field}`}
            </Text>
            <Icon
              color={Token.color.greyTone50}
              name={open ? 'ChevronUp' : 'ChevronDown'}
              size={16}
            />
          </Flex>
        )}
      />
    </CellWithError>
  )
}

interface TextCellProps<T> {
  data: ImportInterface<T>
  onChange: (rowId: number, value: string | number, field: string) => void
  field: string
}

export const TextCell = <T,>({ data, onChange, field }: TextCellProps<T>) => {
  const dataField = get(data.data, field)

  const [value, setValue] = useState<string | number>(dataField || '')

  useEffect(() => {
    if (!!dataField && dataField !== value) {
      setValue(dataField)
    }
  }, [dataField])

  return (
    <CellWithError data={data} field={field}>
      <EditableCell
        type={TableCellInputType.text}
        value={value}
        onChange={val => setValue(val)}
        onBlur={val => onChange(data.id, val, field)}
        hidePencil
      />
    </CellWithError>
  )
}

interface CellWithErrorProps<T> {
  data: ImportInterface<T>
  field: string
  children: React.ReactNode
}

const CellWithError = <T,>({ data, field, children }: CellWithErrorProps<T>) => {
  const tooltip = useTooltip()

  const errors = get(data.errors, field)?.join('\n')
  const loading = get(data.loading, field)

  if (loading) {
    return <TextSkeleton />
  }

  return (
    <Flex alignItems="center" gap="s-8" {...tooltip.getAnchorProps()}>
      <Box
        flex="1"
        color={errors ? Token.color.error : undefined}
        style={{ overflowX: 'hidden' }}
      >
        {children}
      </Box>
      <Tooltip {...tooltip.getTargetProps()} hidden={!errors}>
        {errors}
      </Tooltip>
    </Flex>
  )
}

interface BulkEditActionProps extends TableActionsProps {
  buttonIcon: IconName
  field: string
  selector: selectorKeys
  label?: string
}

export const BulkEditAction = ({
  buttonIcon,
  field,
  selector,
  sessionData,
  getSelectedItems,
  refreshTableState,
  apiEndpoint,
  someSelected,
  label,
}: BulkEditActionProps) => {
  const visibleLabel = label || field

  const [bulkEditPending, setBulkEditPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [option, setOption] = useState<IdAndName | null>(null)
  const statusPopup = useStatusPopup()

  const onAssign = () => {
    if (!sessionData?.id) {
      return
    }

    const items = getSelectedItems()

    setBulkEditPending(true)

    bulkEditImportSessionRows(apiEndpoint, sessionData.id, items, {
      [field]: option?.name || null,
    })
      .then(() => {
        setPopupOpen(false)
        setBulkEditPending(false)
        refreshTableState()
      })
      .catch(error => {
        setBulkEditPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <>
      <MoreBar.Action
        useIcon={buttonIcon}
        disabled={!someSelected}
        onClick={() => setPopupOpen(true)}
      >
        Change {visibleLabel}
      </MoreBar.Action>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        variant="bottom-sheet"
        preventUserClose={bulkEditPending}
      >
        <Header variant="bottom-sheet">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Assign {visibleLabel}</Header.Title>
        </Header>

        <RadioSelectInput
          label={`Select ${visibleLabel}`}
          value={option}
          onChange={setOption}
          selector={selector}
        />

        <Popup.Actions>
          <Footnote>This will replace the {visibleLabel} already assigned</Footnote>
          <Button
            onClick={onAssign}
            elevated
            pending={bulkEditPending}
            disabled={!option}
          >
            Assign
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

interface BulkDeleteButtonProps extends TableActionsProps {}

export const BulkDeleteButton = ({
  sessionData,
  getSelectedItems,
  refreshTableState,
  apiEndpoint,
  someSelected,
}: BulkDeleteButtonProps) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [deleteInBulkPending, setDeleteInBulkPending] = useState(false)

  const statusPopup = useStatusPopup()

  return (
    <>
      <MoreBar.Action
        useIcon="Delete"
        disabled={!someSelected}
        onClick={() => setDeleteConfirmationOpen(true)}
        variant="negative"
      >
        Delete
      </MoreBar.Action>

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onReject={() => setDeleteConfirmationOpen(false)}
        loading={deleteInBulkPending}
        onConfirm={async () => {
          if (!sessionData) {
            return
          }

          const items = getSelectedItems()

          try {
            setDeleteInBulkPending(true)
            await bulkDeleteImportSessionRows(apiEndpoint, sessionData.id, items)
            refreshTableState()
            setDeleteConfirmationOpen(false)
          } catch (error) {
            statusPopup.show(
              <StatusPopup variant="error">
                <StatusPopup.Title>Failed to remove items</StatusPopup.Title>
                <StatusPopup.Description>
                  {getStringMessageFromError(error)}
                </StatusPopup.Description>
                <StatusPopup.Actions>
                  <Button onClick={() => statusPopup.hide()} elevated>
                    Close
                  </Button>
                </StatusPopup.Actions>
              </StatusPopup>,
            )
          } finally {
            setDeleteInBulkPending(false)
          }
        }}
        label="Are you sure you want to remove these items?"
        body=""
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}

interface BulkEditExistingEntitiesActionProps extends TableActionsProps {
  buttonIcon: IconName
  field: string
  selector: selectorKeys
  label?: string
}

export const BulkEditExistingEntitiesAction = ({
  buttonIcon,
  field,
  selector,
  getSelectedItems,
  apiEndpoint,
  someSelected,
  label,
}: BulkEditExistingEntitiesActionProps) => {
  const visibleLabel = label || field

  const [bulkEditPending, setBulkEditPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const [option, setOption] = useState<IdAndName | null>(null)

  const statusPopup = useStatusPopup()

  const onAssign = async () => {
    const items = getSelectedItems()

    setBulkEditPending(true)

    try {
      const createImportSessionResponse = await createImportFromEntities(
        apiEndpoint,
        items,
      )
      const id = createImportSessionResponse.data.bulk_upload_id
      const sessionIdsResponse = await getImportSessionBulkItems(apiEndpoint, id)

      await bulkEditImportSessionRows(apiEndpoint, id, sessionIdsResponse.data.ids, {
        [field]: option?.name || null,
      })

      navigateTo(
        pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION, {
          id,
        }),
      )
    } catch (error) {
      setBulkEditPending(false)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to assign</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }

  return (
    <>
      <MoreBar.Action
        useIcon={buttonIcon}
        disabled={!someSelected}
        onClick={() => setPopupOpen(true)}
      >
        Change {visibleLabel}
      </MoreBar.Action>

      <Popup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        variant="bottom-sheet"
        preventUserClose={bulkEditPending}
      >
        <Header variant="bottom-sheet">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Assign {visibleLabel}</Header.Title>
        </Header>

        <RadioSelectInput
          label={`Select ${visibleLabel}`}
          value={option}
          onChange={setOption}
          selector={selector}
        />

        <Popup.Actions>
          <Footnote>This will replace the {visibleLabel} already assigned</Footnote>
          <Button
            onClick={onAssign}
            elevated
            pending={bulkEditPending}
            disabled={!option}
          >
            Assign
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import {
  Avatar,
  Banner,
  Box,
  Cell,
  MoreBar,
  Subheader,
  Token,
  VStack,
  Text,
} from '@revolut/ui-kit'

import RadioSwitch from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { selectorKeys } from '@src/constants/api'
import { AudienceTable, TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { IdAndName } from '@src/interfaces'
import { DocumentsBulkRequestInterface } from '@src/interfaces/documents'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CommonTabProps } from '@src/apps/People/Documents/BulkRequest/common'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { attachDocumentsBulkRequestEligibilityGroup } from '@src/api/documents'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectUser } from '@src/store/auth/selectors'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { TemplateItem } from '@src/apps/People/Documents/BulkRequest/Edit/components/TemplateItem'
import { AttachedTemplateInterface } from '@src/interfaces/documentsTemplates'

type EligibilityType = 'eligibility_group' | 'custom_filters' | 'custom_data'

const NO_TEMPLATES_SELECTED_ERR = 'No document items selected'

type Props = CommonTabProps & {
  attachedTemplates: AttachedTemplateInterface[]
  initAttachedTemplates: (templates: AttachedTemplateInterface[]) => void
  deleteAttachedTemplate: (templateId: number) => void
  openTemplatesSidebar: () => void
}
export const BasicsTabContent = connect(
  ({
    nextTabPath,
    updateBulkRequestData,
    attachedTemplates = [],
    initAttachedTemplates,
    deleteAttachedTemplate,
    openTemplatesSidebar,
  }: Props) => {
    const { values, submit } = useLapeContext<DocumentsBulkRequestInterface>()
    const currentUser = useSelector(selectUser)

    useEffect(() => {
      const currentUserValue = pick(currentUser, [
        'id',
        'full_name',
      ]) as EmployeeOptionInterface

      if (values.document_templates?.length) {
        initAttachedTemplates(values.document_templates)
      }
      if (!values.created_by) {
        values.created_by = currentUserValue
      }
      if (!values.issuer) {
        values.issuer = currentUserValue
      }
    }, [])

    useEffect(() => {
      if (attachedTemplates.length) {
        setShowEntitiesRequiredBanner(false)
      }
    }, [attachedTemplates.length])

    const [eligibilityType, setEligibilityType] = useState<EligibilityType>(
      isEmpty(values.employees_filters) ? 'eligibility_group' : 'custom_filters',
    )

    const templatesListRef = useRef<HTMLDivElement>(null)
    const [showEntitiesRequiredBanner, setShowEntitiesRequiredBanner] = useState(false)

    const eligibilityGroupDropdownRef = useRef<HTMLDivElement>(null)
    const eligibilityGroupTableRef = useRef<HTMLDivElement>(null)
    const [showEmptyEligibilityGroupError, setShowEmptyEligibilityGroupError] =
      useState(false)

    const [customFilters, setCustomFilters] = useState<TableFilter | null>(
      values.employees_filters,
    )
    const [eligibilityGroup, setEligibilityGroup] = useState<IdAndName | null>(
      values.dynamic_group,
    )

    return (
      <>
        <VStack space="s-32">
          <VStack space="s-16" ref={templatesListRef}>
            <LapeNewInput required name="name" label="Request name" />
            <LapeRadioSelectInput
              name="issuer"
              label="Issuer"
              selector={selectorKeys.employee}
            />
            <Subheader>
              <Subheader.Title>
                Select request template from the list or create a new one
              </Subheader.Title>
            </Subheader>
            <Cell pt={attachedTemplates.length ? 's-16' : undefined}>
              <VStack width="100%" space="s-16">
                {showEntitiesRequiredBanner && (
                  <Banner p={0}>
                    <Banner.Avatar>
                      <Avatar
                        useIcon="16/Warning"
                        size={40}
                        bg={Token.color.red_10}
                        color={Token.color.red}
                      />
                    </Banner.Avatar>
                    <Banner.Content>
                      <Banner.Title>This field can't be empty</Banner.Title>
                      <Banner.Description>
                        Create new document request or select an existing template
                      </Banner.Description>
                    </Banner.Content>
                  </Banner>
                )}
                {!!attachedTemplates.length && (
                  <VStack>
                    {attachedTemplates.map(template => (
                      <TemplateItem
                        canPreview
                        key={template.id}
                        template={template}
                        handleDelete={() => deleteAttachedTemplate(template.id)}
                      />
                    ))}
                  </VStack>
                )}
                {!attachedTemplates.length && !showEntitiesRequiredBanner && (
                  <Text variant="h6" color={Token.color.greyTone50}>
                    Nothing selected yet
                  </Text>
                )}
                <MoreBar>
                  <MoreBar.Action useIcon="Plus" onClick={openTemplatesSidebar}>
                    Add request template
                  </MoreBar.Action>
                </MoreBar>
              </VStack>
            </Cell>
          </VStack>
          <VStack space="s-16">
            <Subheader>
              <Subheader.Title>
                Define the group of employees that you want to bulk request documents from
              </Subheader.Title>
            </Subheader>
            <RadioSwitch<EligibilityType>
              highlightSelected
              value={eligibilityType}
              onChange={setEligibilityType}
              options={[
                {
                  id: 'eligibility_group',
                  value: 'eligibility_group',
                  label: 'Select an existing group',
                  description:
                    'Assign employees from an existing group automatically to this request.',
                },
                {
                  id: 'custom_filters',
                  value: 'custom_filters',
                  label: 'Custom filters',
                  description:
                    'Assign eligibility for this request manually by configuring table filters.',
                },
                // TODO: Add custom data eligibility option when implemented on BE
                // {
                //   id: 'custom_data',
                //   value: 'custom_data',
                //   label: 'Custom data',
                //   description:
                //     'Upload a CSV/XLSX file with custom data to reference in your flow.',
                // },
              ]}
            />
            {eligibilityType === 'eligibility_group' && (
              <Cell p="s-8">
                <VStack width="100%">
                  <Banner>
                    <Banner.Content>
                      <Banner.Title>Select group</Banner.Title>
                      <Banner.Description>
                        Whom should this request be applied to?
                      </Banner.Description>
                    </Banner.Content>
                  </Banner>
                  <Box px="s-8" pb="s-8">
                    <RadioSelectInput<IdAndName>
                      hasError={showEmptyEligibilityGroupError}
                      value={eligibilityGroup}
                      label="Group"
                      selector={selectorKeys.dynamic_groups}
                      onChange={newGroup => {
                        if (!newGroup) {
                          return
                        }
                        setEligibilityGroup(newGroup)
                        setShowEmptyEligibilityGroupError(false)
                      }}
                      inputProps={{
                        errorMessage: showEmptyEligibilityGroupError
                          ? 'Please select group from the list'
                          : '',
                      }}
                    />
                    <div ref={eligibilityGroupDropdownRef} />
                  </Box>
                </VStack>
              </Cell>
            )}
            {eligibilityType === 'custom_filters' && (
              <Cell pt={0} ref={eligibilityGroupTableRef}>
                <VStack width="100%" space="s-16">
                  <Subheader>
                    <Subheader.Title>
                      Set filters to define eligible employees
                    </Subheader.Title>
                  </Subheader>
                  {showEmptyEligibilityGroupError && (
                    <Banner p={0}>
                      <Banner.Avatar>
                        <Avatar
                          useIcon="16/Warning"
                          size={40}
                          bg={Token.color.red_10}
                          color={Token.color.red}
                        />
                      </Banner.Avatar>
                      <Banner.Content>
                        <Banner.Title>
                          Please, configure table filters to define custom eligibility
                          group
                        </Banner.Title>
                      </Banner.Content>
                    </Banner>
                  )}
                  <AudienceTable
                    filter={customFilters}
                    onFilterChange={newFilters => {
                      setCustomFilters(newFilters)
                      setShowEmptyEligibilityGroupError(false)
                    }}
                  />
                </VStack>
              </Cell>
            )}
          </VStack>
        </VStack>
        <PageActions>
          <NewSaveButtonWithPopup
            mt="s-64"
            useValidator
            hideWhenNoChanges={false}
            onClick={async () => {
              if (attachedTemplates.length) {
                values.document_templates = attachedTemplates
              } else {
                throw new Error(NO_TEMPLATES_SELECTED_ERR)
              }
              const res = await submit()
              updateBulkRequestData?.(res)
              const requestId = values.id || res.id

              if (requestId) {
                await attachDocumentsBulkRequestEligibilityGroup(
                  requestId,
                  eligibilityType === 'custom_filters'
                    ? { employees_filters: customFilters, dynamic_group: null }
                    : eligibilityType === 'eligibility_group'
                    ? { dynamic_group: eligibilityGroup, employees_filters: null }
                    : undefined,
                )
                navigateReplace(pathToUrl(nextTabPath, { id: String(requestId) }))
              }
              return res
            }}
            errorHandler={err => {
              let isHandled

              if (err.message === NO_TEMPLATES_SELECTED_ERR) {
                setShowEntitiesRequiredBanner(true)
                isHandled = true
                templatesListRef.current?.scrollIntoView({ behavior: 'smooth' })
              } else if (
                err.response?.status === 400 &&
                err.response?.data?.non_field_errors?.includes?.(
                  'Neither dynamic group nor employee filters are selected',
                )
              ) {
                setShowEmptyEligibilityGroupError(true)
                isHandled = true

                if (eligibilityType === 'eligibility_group') {
                  eligibilityGroupDropdownRef.current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                } else if (eligibilityType === 'custom_filters') {
                  eligibilityGroupTableRef.current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                }
              }
              if (!isHandled) {
                throw err
              }
            }}
          >
            Next
          </NewSaveButtonWithPopup>
        </PageActions>
      </>
    )
  },
)

import { RequestInterfaceNew, GetRequestData } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { useFetch, useDelete } from '@src/utils/reactQuery'
import { TicketInterface, TicketFullInterface } from '@src/interfaces/tickets'
import { FilterByInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { getCommentsAPI } from '@src/api/comments'
import { uploadFile } from '@src/api/files'

const createFiles = (files: File[], endpoint: string) => {
  return Promise.allSettled(
    files.map(file =>
      uploadFile(file, 'ticket', undefined, undefined, undefined, endpoint),
    ),
  )
}

export const ticketsRequests: RequestInterfaceNew<TicketInterface> = {
  get: async ({ id }) => api.get(`${API.TICKETS}/${id}`),
  submit: async data => {
    const { files, ...rest } = data

    const result = await apiWithoutHandling.post(`${API.TICKETS}`, rest)

    if (files) {
      await createFiles(files, `${API.TICKETS}/${result.data.id}/files`)
    }

    return result
  },
  update: async (data, { id }) => {
    const { files, ...rest } = data

    if (files) {
      await createFiles(files, `${API.TICKETS}/${id}/files`)
    }
    return apiWithoutHandling.patch(`${API.TICKETS}/${id}`, rest)
  },
}

export const uploadTicketFiles = async (id: string, files: File[]) => {
  await createFiles(files, `${API.TICKETS}/${id}/files`)
}

export const useGetTickets = (filtersParams: FilterByInterface[]) =>
  useFetch<GetRequestData<TicketFullInterface>>(API.TICKETS, 'v1', {
    params: filterSortPageIntoQuery(undefined, filtersParams),
  })

export const useGetTicket = (id?: string) => {
  return useFetch<TicketFullInterface>(id ? `${API.TICKETS}/${id}` : null)
}

export const reopenTicket = (id: string | number) =>
  apiWithoutHandling.post(`${API.TICKETS}/${id}/reopen`)

export const closeTicket = (id: string | number) =>
  apiWithoutHandling.post(`${API.TICKETS}/${id}/close`)

export const getTicketsCommentsAPI = (id: number) =>
  getCommentsAPI({
    baseUrl: `${API.TICKETS}/${id}/comments`,
  })

export const useTicketFileDelete = (id: number) => useDelete(`${API.TICKETS}/${id}/files`)

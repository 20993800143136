import React from 'react'

import { CellTypes } from '@src/interfaces/data'
import { ImportEmployeeDataInterface } from '@src/interfaces/importEmployees'
import { selectorKeys } from '../api'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import {
  ImportSelectInputColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'

type ImportEmployeeColumn = ImportSelectInputColumn<
  ImportEmployeeDataInterface | RevolutersInterface
>

export const importEmployeesV2FirstNameColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'first_name',
  dataPoint: 'first_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'First name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="first_name" />,
})

export const importEmployeesV2LastNameColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'last_name',
  dataPoint: 'last_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="last_name" />,
})

export const importEmployeesV2EmailColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Work email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

export const importEmployeesV2TeamColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
      useNameField
    />
  ),
})

export const importEmployeesV2RoleColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.specialisations}
      useNameField
      fieldName="role"
    />
  ),
})

export const importEmployeesV2SeniorityColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="seniority"
      selector={selectorKeys.seniority}
      useNameField
    />
  ),
})

export const importEmployeesV2AccessGroupColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'access_level',
  dataPoint: 'access_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Access group',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="access_level"
      fieldName="access group"
      selector={selectorKeys.groups}
      useNameField
    />
  ),
})

export const onboardingEmployeesFullNameColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="full_name" />,
})

export const onboardingEmployeesEmailColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: 'email',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Work email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

export const onboardingEmployeesTeamColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
    />
  ),
})

export const onboardingEmployeesRoleColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.specialisations}
      fieldName="role"
    />
  ),
})

export const onboardingEmployeesSeniorityColumn: ImportEmployeeColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="seniority"
      selector={selectorKeys.seniority}
    />
  ),
})

import { OptionInterface } from '@src/interfaces/selectors'
import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import {
  EmployeeBasicInterface,
  EmployeeOptionInterface,
  AvatarType,
} from '@src/interfaces/employees'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import { Employee } from '@src/interfaces/employeeChangeRequest'
import { FileInterface } from '@src/interfaces/files'
import {
  DurationUnitType,
  InterviewStageInterface,
  SchedulingCalendarInterface,
  SnoozeCandidateInterface,
} from '@src/interfaces/interviewTool'
import { ApplicationDataInterface } from '@src/interfaces/applicationQuestions'

export interface OnlineTestInterface {
  id: number
  name: string
  duration?: number
  test_url?: string
  is_active?: boolean
  external_id?: string
  platform?: string
}

export interface OnlineTestResultInterface {
  id: number
  external_id: number | string
  platform: string
  name?: string
  duration?: number
  test_url?: string
  test_report?: FileInterface | null
  score?: number
  percentage_score?: number
  is_passed?: boolean
  is_plagiarized?: boolean
  sent_date_time?: string
  started_date_time?: string
  completed_date_time?: string
  report_url?: string
}

export type StageType =
  | 'cv_screening'
  | 'screen_call'
  | 'home_assessment'
  | 'skills_round'
  | 'online_test'
  | 'final_round'

export interface StageTypeInterface extends Omit<OptionInterface, 'id'> {
  id: StageType
}

export type OnlineTestPlatform = 'hackerrank' | 'criteriacorp' | 'hirevue'

export interface HiringProcessInterface {
  id: number
  stage_type: StageTypeInterface
  title: string
  duration: number
  duration_unit: OptionInterface
  playbook_link: string
  is_scorecard_generated: boolean
  creation_date_time: string
  updated_date_time: string
  role?: { id: number; name?: string; status?: Statuses }
  specialisation?: { id: number; name?: string }
  notes?: string
  company_hiring_stage?: { id: number; name?: string } | null
  role_hiring_stage?: { id: number; name?: string }
  interviewer_type: OptionInterface
  interviewers?: EmployeeOptionInterface[]
  interviewers_groups?: OptionInterface[]
  owner?: {
    id: number
    full_name: string
    status?: string
    avatar?: AvatarType
  }
  scorecard_template?: OptionInterface
  skills: OptionInterface[]
  field_options?: FieldOptions
  is_company_standard_round?: boolean
  is_limited_by_seniorities?: boolean
  limit_editing_in_specialisation?: boolean
  seniority_max?: SeniorityInterface
  seniority_min?: SeniorityInterface
  index?: number
  test_platform?: IdAndName<OnlineTestPlatform>
  online_test?: OnlineTestInterface
  online_test_result?: OnlineTestResultInterface
  status?: Statuses
  send_test_automatically?: boolean
  send_auto_email_template?: OptionInterface
  archive_candidate_automatically?: boolean
  online_test_pass_score?: number
  archive_auto_email_template?: OptionInterface
  auto_archival_reason?: OptionInterface
  hiring_process: SpecialisationHiringProcess | null
}

export type HiringStageType = 'specialisation' | 'role' | 'general'

export type EmailDirection = 'sent' | 'received'

export interface SendCandidateEmailInterface
  extends Pick<
    EmailTemplateInterface,
    | 'id'
    | 'sender_type'
    | 'subject'
    | 'recipients_cc'
    | 'recipients_bcc'
    | 'email_body'
    | 'attachments'
  > {
  status: 'scheduled' | 'pending' | 'delivered' | 'canceled'
  creation_date_time: string
  when_to_send?: OptionInterface
  custom_sending_datetime?: string
  canceled?: boolean
  snippet: string
  sender_employee?: Employee
  direction: EmailDirection
  sender_email: string
  sender_name?: string
  recipient_email: string
  recipient_name?: string
  email_template?: OptionInterface
  reply_email?: {
    id: number
  } | null
  candidate_ids?: (number | string)[]
  interview_round_ids?: (number | string)[]
  summary?: string
  meeting_link?: string
  event_title?: string
  duration?: number
  duration_unit?: IdAndName<DurationUnitType>
  additional_interviewers?: EmployeeOptionInterface[]
  interviewer?: EmployeeOptionInterface
  interview_stage?: InterviewStageInterface
  scheduling_timezone?: IdAndName<string>
  custom_date?: {
    day?: string
    time?: string
    timeError?: string
  }
  calendar?: SchedulingCalendarInterface | null
  is_candidate_involved?: boolean
}

export interface SendCandidateEmailPayloadInterface
  extends Pick<
    SendCandidateEmailInterface,
    | 'email_body'
    | 'email_template'
    | 'recipients_bcc'
    | 'recipients_cc'
    | 'sender_type'
    | 'subject'
    | 'when_to_send'
    | 'custom_sending_datetime'
  > {
  attachments?: FileInterface[]
}

export enum WhenToSend {
  NOW = 'send_now',
  CUSTOM = 'custom_date',
}

export interface EmailThreadInterface {
  id: number
  subject: string
  creation_date_time: string
  update_date_time: string
  latest_sent_date_time: string
  candidate_emails: SendCandidateEmailInterface[]
}

export interface CandidateFormSourceInterface {
  id: 'application' | 'sourcing' | 'referral' | 'internal'
  name: 'string'
}

export interface CandidateFormInterface {
  id: number
  email: string
  full_name: string
  created_date_time: string
  recruiter?: EmployeeBasicInterface
  referred_by?: EmployeeBasicInterface
  created_by?: EmployeeBasicInterface
  source: CandidateFormSourceInterface
  application_form_data?: ApplicationDataInterface
}

export interface CandidateStatsInterface {
  form_count: number
  thread_count: number
  comment_count: number
  timeline_event_count: number
  file_count: number
}

export type CandidateDocumentCategory =
  | 'candidate_resume'
  | 'online_test_report'
  | 'candidate_other'

export interface CandidateFileInterface extends FileInterface {
  category: {
    id: CandidateDocumentCategory
    name: string
  }
}

export interface CandidateDocumentInterface {
  file: CandidateFileInterface
  name?: string
}

export interface BaseBulkActionsInterface {
  id: string
  candidate_ids: number[]
  request_employee: EmployeeOptionInterface
  status: Statuses
  creation_date_time: string
  update_date_time: string
  completed_date_time: string
  failed_candidate_ids: number[]
}

export interface CandidateBulkEmailsInterface
  extends BaseBulkActionsInterface,
    Pick<
      SendCandidateEmailInterface,
      | 'sender_type'
      | 'subject'
      | 'recipients_cc'
      | 'recipients_bcc'
      | 'email_body'
      | 'attachments'
      | 'email_template'
      | 'when_to_send'
      | 'custom_sending_datetime'
    > {}

export interface CandidateBulkArchiveInterface extends BaseBulkActionsInterface {
  archive_all_rounds: boolean
  archived_reason: string
  archived_comment?: string
}

export interface CandidateBulkEditInterface extends BaseBulkActionsInterface {
  edit_fields: string[]
}

export interface CandidateBulkMoveStageInterface extends BaseBulkActionsInterface {
  stage_hiring_stage_id: string
  stage_hiring_stage_type: StageType
}

export interface CandidateBulkSnoozeInterface
  extends BaseBulkActionsInterface,
    Pick<
      SnoozeCandidateInterface,
      'comment' | 'notify_employees' | 'snooze_until' | 'snoozed_by'
    > {}

export interface CandidateBulkUnsnoozeInterface extends BaseBulkActionsInterface {}

export interface CandidateBulkSendOnlineTestInterface extends BaseBulkActionsInterface {}

export interface ConsentRequiredInterface {
  is_consent_required: boolean
}

export interface SpecialisationsHiringProcessOverviewInterface {
  id: number
  name: string
  hiring_process_rounds: HiringProcessInterface[]
}

export type SpecialisationHiringProcess = {
  id: number
  name: string
  description: string
  specialisation: IdAndName<number, string>
  // TODO: replace is_archived and is_draft for status: 'draft' | 'active' | 'archived'
  is_archived: boolean
  is_draft: boolean
  is_default: boolean
  hiring_process_stages?: HiringProcessInterface[]
}

import { Token } from '@revolut/ui-kit'

import {
  DocumentsBulkRequestEmployeeValidationStatus,
  DocumentsBulkRequestInterface,
  DocumentsBulkRequestStatus,
} from '@src/interfaces/documents'
import { documentsBulkRequestFormRequests } from '@src/api/documents'
import { useEffect, useRef, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'

export type CommonTabProps = {
  nextTabPath: string
  bulkRequestData: DocumentsBulkRequestInterface | undefined
  updateBulkRequestData?: (data: DocumentsBulkRequestInterface) => void
}

export const getRefreshRequestData = (requestId: number | string) => () =>
  documentsBulkRequestFormRequests.get({ id: String(requestId) }).then(res => res.data)

export const getEmployeeValidationStatusColor = (
  status: DocumentsBulkRequestEmployeeValidationStatus,
): string => {
  switch (status.id) {
    case 'validation_pending':
      return Token.color.warning
    case 'validation_completed':
    case 'submit_completed':
      return Token.color.success
    case 'validation_failed':
      return Token.color.error
    default:
      return Token.color.foreground
  }
}

export const getDocumentsBulkRequestStatusColor = (
  status: DocumentsBulkRequestStatus,
): string => {
  switch (status.id) {
    case 'validation_completed':
    case 'validation_pending':
    case 'validation_in_progress':
    case 'submit_in_progress':
    case 'ready_to_be_submitted':
      return Token.color.warning
    case 'submit_completed':
      return Token.color.success
    default:
      return Token.color.foreground
  }
}

export const useRefreshRequestStatus = (onUpdate?: () => void) => {
  const { values, reset } = useLapeContext<DocumentsBulkRequestInterface>()
  const refreshRequestData = getRefreshRequestData(values.id)

  const [isPolling, setIsPolling] = useState(false)

  const tmo = useRef<NodeJS.Timeout | null>(null)
  const int = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isPolling) {
      return undefined
    }
    setIsPolling(true)

    const cleanUp = (data?: DocumentsBulkRequestInterface) => {
      if (tmo?.current) {
        clearTimeout(tmo.current)
      }
      if (int?.current) {
        clearInterval(int.current)
      }
      if (data) {
        reset(data)
      }
      setIsPolling(false)
    }
    // refresh status after 500ms delay before setting interval to make sure it's not a quick validation process
    tmo.current = setTimeout(() => {
      refreshRequestData().then(data => {
        if (data.status.id === 'ready_to_be_submitted') {
          cleanUp(data)
        } else {
          onUpdate?.()
          int.current = setInterval(() => {
            onUpdate?.()
            refreshRequestData().then(refetchedData => {
              if (refetchedData.status.id !== 'validation_in_progress') {
                cleanUp(refetchedData)
              }
            })
          }, 3000)
        }
      })
    }, 500)

    return cleanUp
  }, [values.status.id])

  return { isPolling }
}

import { FileInterface } from '@src/interfaces/files'

export interface TicketInterface {
  id: number
  title: string
  text?: string
  extra_info?: string
  url?: string
  is_extra_info_shared: boolean
  files?: File[]
}

export const ticketStatuses = ['submitted', 'reviewed', 'fixed', 'closed'] as const
export type TicketStatus = typeof ticketStatuses[number]

export interface TicketFullInterface extends Omit<TicketInterface, 'files'> {
  is_extra_info_shared: boolean
  is_communication_allowed: boolean
  jira_id?: string
  jira_url: string | null
  employee_uuid: string
  employee_first_name: string
  employee_last_name: string
  employee_avatar: string
  tenant_uuid?: string
  creation_date_time: string
  current_status: {
    status: TicketStatus
    creation_date_time: string
  }
  status_list: {
    status: TicketStatus
    creation_date_time: string
  }[]
  files: FileInterface[]
}
